<template>
  <div class="view-payGuide">
    <template v-if="wx()">
      <div class="arrow">
        <div class="tips">
          <div>点击这里</div>
          <div>用系统浏览器自带浏览器打开</div>
        </div>
        <img src="@/assets/imgs/account/guideArrow.png" alt="" />
      </div>
      <div class="title">{{ systemInfo.company }}提醒您</div>
      <ul class="tips">
        <li>请不要使用QQ浏览器打开，<span>使用手机自带浏览器</span></li>
        <li><span>请不要退出网页</span>，按照视频所展示的流程支付</li>
      </ul>
      <div class="form">
        <video
          controls
          src="https://yisaas-pub.oss-cn-beijing.aliyuncs.com/video/alipay_video.m4v"
        ></video>
      </div>
      <div class="view-button">
        <van-button plain type="info" @click="back">
          返回上一步
        </van-button>
        <van-button type="info" @click="getPayStatus">
          确认已支付
        </van-button>
      </div>
    </template>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    data() {
      return {
        tradeId: null,
        payToken: null,
      }
    },
    created() {
      this.tradeId = this.$route.query.tradeId
      this.payToken = this.$route.query.payToken

      if (this.wx()) {
        // 微信内
      } else {
        // 跳转支付宝
        window.location.href = this.payToken
      }
    },
    computed: {
      ...mapState({
        systemInfo: state => state.system,
      }),
    },
    methods: {
      wx() {
        return (
          navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1
        )
      },
      getPayStatus() {
        this.$router.push({
          path: '/payStatus',
          query: {
            tradeId: this.tradeId,
            path: this.$route.query.path,
            key: this.$route.query.key,
          },
        })
      },
      back() {
        window.history.back()
      },
    },
  }
</script>

<style lang="less" scoped>
  .view-payGuide {
    background-color: #fff;
    padding-bottom: 70px;
    .arrow {
      padding-top: 13px;
      display: flex;
      margin-bottom: 28px;
      padding-right: 38px;
      .tips {
        flex: 1;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: right;
        color: #677283;
        line-height: 22px;
      }
      img {
        width: 70px;
        height: 32px;
        margin-left: 19px;
      }
    }
    .title {
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #111a34;
      line-height: 22px;
      margin-bottom: 11px;
      padding: 0 20px;
    }
    .tips {
      padding: 0 20px;
      margin-bottom: 16px;
      li {
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #677283;
        line-height: 22px;
        margin-bottom: 12px;
        span {
          color: #f15643;
        }
        &:before {
          content: '';
          display: inline-block;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: #677283;
          margin-right: 7px;
          vertical-align: middle;
          margin-top: -4px;
        }
      }
    }
    .form {
      width: 226px;
      // height: 402px;
      background: #e7e7e7;
      margin: 0 auto;
      margin-bottom: 37px;
      video {
        width: 100%;
      }
    }
  }
</style>
